<template>
  <el-dialog
    :visible="visible"
    title="账单手续费修改"
    @opened="afterOpen"
    @close="onClose"
    width="500px"
  >
    <el-form ref="form" :model="form" label-width="180px">
      <el-form-item label="修改前金额">
        {{ amount }}
      </el-form-item>
      <el-form-item
        label="修改后金额"
        prop="amount"
        :rules="[{ required: true, message: '请输入修改后金额' }]"
      >
        <el-input-number
          v-model="form.amount"
          size="small"
          placeholder="请输入修改后金额"
          :controls="false"
          :min="0.01"
          class="form-item"
        />
      </el-form-item>
      <el-form-item
        label="请再次输入修改后金额"
        prop="confirmAmount"
        :rules="confirmAmountRules"
      >
        <el-input-number
          v-model="form.confirmAmount"
          size="small"
          placeholder="请再次输入修改后金额"
          :controls="false"
          :min="0.01"
          class="form-item"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { editCommision } from "./api.js";

export default {
  name: "EditCommision",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    amount: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        amount: undefined,
        confirmAmount: undefined,
      },
      confirmAmountRules: [
        {
          required: true,
          message: "请再次输入修改后金额",
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (value !== this.form.amount) {
              callback(new Error("两次输入金额不一致"));
            } else {
              callback();
            }
          },
        },
      ],
    };
  },
  methods: {
    async afterOpen() {
      this.reset();
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    async onSubmit() {
      await this.$refs.form.validate();

      await editCommision({
        id: this.id,
        amount: this.form.amount,
      });

      this.onClose();

      this.$emit("refreshList");
    },
    reset() {
      this.$set(this, "form", this.$options.data().form);

      this.$refs.form.resetFields();
    },
  },
};
</script>
<style scoped lang="scss">
.form-item {
  width: 200px;
}
</style>
